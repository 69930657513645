/*XSS and CSRF changes - Chinmay - START*/
function res_gettoken(hotelid) {
 try{
		getUrl=serverurl+'service.php';
		jQuery.ajax({
			  type: "POST",
			  url: getUrl,
			  data: {action: 'gettoken',HotelId: hotelid},
			  success: function(response) {
					var token_val=$.trim(response);
					//console.log(token_val);
					jQuery("input[name='restoken']").remove();
					jQuery("div.GetToken").append("<input type='hidden' name='restoken' id='restoken' value=''>");
					jQuery("#restoken").val(token_val);
			   },//success
			   complete: function(){
					  //console.log( "AJAX - complete()" );
			  }
		  });
 }
 catch(err){
		console.log('error message res_gettoken');
		console.log(err);
	}
}
function reg_token(hotelid)
{
	try
	{
		   getUrl=serverurl+'service.php';
		   jQuery.ajax({
					type: "POST",
					url: getUrl,
					data: {action: 'gettoken',HotelId: hotelid},
					success: function(response) {
						  var token_val=$.trim(response);
						  //console.log(token_val);
						  jQuery("input[name='restoken']").remove();
						  jQuery("div.Reg_Token").append("<input type='hidden' name='restoken' id='restoken' value=''>");
						  jQuery("#restoken").val(token_val);
					},//success
					complete: function()
					{
						 
					}
			 });
	}
	catch(err)
	{
		   console.log('error message res_gettoken');
		   console.log(err);
	}
}
/*XSS and CSRF changes - Chinmay - END*/
function showCheckavalabilityLoadingBar()
{
		$('.vres-prog-wrap').fadeIn();
        // $('.vres-prog-wrap').find('.inner-prog').animate({'width': '100%'}, 4800);
		//$('.vres-prog-wrap').fadeOut();
		//$('.page-wrapper').removeClass('wrap_hid');
		//$('.vres-search').show();
		//if($(window).width() < 768){
		//	var docHeight = $('html').height(),
		//		winHeight = $(window).height();
		//	
		//	if(winHeight < docHeight){
		//		$('footer').removeClass('footerFixed');
		//		$('footer').addClass('marBotom');
		//	} else {
		//		$('footer').addClass('footerFixed');
		//		$('footer').removeClass('marBotom');
		//	}
		//}
		//var offHeight = $('.vres-specialoffer').height();
		//$('.vres-specialoffer').css({'margin-top': - offHeight, 'transition':'all ease .3s'});
}
function hideCheckavalabilityLoadingBar(){
	//$('.vres-prog-wrap').find('.inner-prog').animate({'width': '0%'}, 0);
	//if($(window).width() < 768){
	//	$('.vres-bar').slideUp();
	//	$('.vres-search').addClass('serach_show');
	//	$('.vres-helpup').css({'right': '180px'});
	//}
	
	$('.vres-prog-wrap').fadeOut();
	$('.page-wrapper').removeClass('wrap_hid');
	$('.vres-search').show();
}

function showLoadingBar()
{
	    _htmlHeight=jQuery(document).height();
        _htmlWidth=jQuery(document).width();
        lboxwidth=jQuery(".loadingbar").width()/2;
        lboxheight=jQuery(".loadingbar").height()/2;
        docwidth=jQuery(document).width()/2;
        docheight=jQuery(document).height()/2;
        var mt = jQuery('.loadingbar').height()/2;
//docwidth=jQuery("#outer").width()/2;
//docheight=jQuery("#outer").height()/2;

_left=docwidth-lboxwidth;
_top=docheight-lboxheight;
jQuery(".transbg").attr("style","display:block");
jQuery(".transbg").css({left:"0px",top:"0px",position:"fixed",width:"100%",height:_htmlHeight+"px",zIndex:"90000"});
jQuery(".loadingbar").css({left:_left+
"px",top:"50%",'margin-top':-mt+"px", position:"fixed",display:"block"})}

function hideLoadingBar(){jQuery(".transbg").fadeOut(30);jQuery(".loadingbar").fadeOut(30)}

function addCommas(nStr){nStr+="";x=nStr.split(".");x1=x[0];x2=x.length>1?"."+x[1]:"";var rgx=/(\d+)(\d{3})/;while(rgx.test(x1))x1=x1.replace(rgx,"$1"+","+"$2");
//Flora - for showing French notation value
var frenchhotel_hotelcode="res_HotelCode";
if(sessionStorage.getItem(frenchhotel_hotelcode)!=null)	
{ 
    var h_code_val=sessionStorage.getItem("res_HotelCode");
    var fr_val=sessionStorage.getItem("res_FrenchHotel_"+h_code_val);
    //console.log("fr_val : "+ fr_val);
    if(fr_val!=null && fr_val=='-fr-French')	
    {
        var tmpnum=x1+x2;
        var res = tmpnum.replace(/,/g , " ");
        res=res.replace(".", ",");
        //console.log("res " +  res + " : " + res.replace(".", ","));
        //var res = res.replace(/./g , ",");
        return res;
    }
    else
    {
        return x1+x2;
    }    
}
//Flora - for showing French notation value
else
{
   return x1+x2;     
}

}

function js_stripslashes(str){if(str!=""&&str!=null&&typeof str!="undefined"){str=str.replace(/\\'/g,"'");str=str.replace(/\\"/g,'"');str=str.replace(/\\0/g,"\x00");str=str.replace(/\\\\/g,"\\");return str}}
function js_addslashes(str) {if(str!=""&&str!=null&&typeof str!="undefined"){ return (str + '').replace(/\\/g, '\\\\').replace(/'/g, "\\'").replace(/"/g, '\\"').replace(/\0/g, '\\0');}}


function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}

//Falguni Rana - Round Off issue, multiplereturn - either 0 or 1, 1 for Round Off
function getTax(roomcharge,taxarray,decimalplaces,flag,BaseRate,AdultRate,ChldRate,base_adult,base_child,callfor,EA,EC,multiplereturn,display_decimalplace,fcurrrates,WebRunForeignCurrency)//,flag=0
{
	try
	{
		fcurrrates=fcurrrates || '';
		WebRunForeignCurrency=WebRunForeignCurrency || '';
        multiplereturn = multiplereturn || '0';
        display_decimalplace = display_decimalplace || decimalplaces;
        multiplereturn = parseInt(multiplereturn);
        /*Flora : its calculating NAN while taxdefination is empty*/
        //console.log(eval(taxarray)[0]);
        if (eval(taxarray)[0]==0) {
            return 0;
        }
        
	if (typeof flag == 'undefined')
   		flag=0;
	if (typeof BaseRate == 'undefined')
   		BaseRate=0;
	if (typeof AdultRate == 'undefined')
   		AdultRate=0;
	if (typeof ChldRate == 'undefined')
   		ChldRate=0;	
	if (typeof callfor == 'undefined')
   		callfor='base';	
	if (typeof EA == 'undefined')
   		EA=0;
	if (typeof EC == 'undefined')
   		EC=0;	
		
	//Manali - 1.0.36.41 - 19 Aug 2013 - START
	//Purpose : Tax apply on Pax Effect
	if (typeof base_adult == 'undefined')
   		base_adult=0;
	if (typeof base_child == 'undefined')
   		base_child=0;
	//Manali - 1.0.36.41 - 19 Aug 2013 - END
		
	//console.log(flag+"|"+roomcharge+"|"+BaseRate+"|"+AdultRate+"|"+ChldRate+"|"+callfor+"|"+EA+"|"+EC);
	var v_amount=parseFloat(roomcharge).toFixed(decimalplaces);  
	var realamt=v_amount;
	var cnt=0;
	var JSONobject=eval(taxarray);
	//console.log(JSONobject);
	var taxamt=new Array();
	var slab=new Array();
	var potype=new Array();
	var porule=new Array(); //Manali - 1.0.36.41 - 19 Aug 2013,Purpose : Tax apply on Pax Effect
	var exrate1=new Array();
	var exrate2=new Array();
	
	var taxapplyafter=new Array();
	var taxunkid=new Array();
	var applyonrackrate=new Array();
	
	if(JSONobject=='')
		return 0;
	
       
       
	jQuery.each(JSONobject, function(jsonKey, jsonValue) {
		jQuery.each(jsonValue,  function(Key, Value) {
				jQuery.each(Value, function(k1, v1) {
						//console.log(k1);					
						//console.log(Value[k1]);					
						if(k1=='amount')
							taxamt.push(Value[k1]);
						if(k1=='slab')
							slab.push(Value[k1]);
						if(k1=='postingtype')
							potype.push(Value[k1]);
						if(k1=='postingrule')  //Manali - 1.0.36.41 - 19 Aug 2013,Purpose : Tax apply on Pax Effect
							porule.push(Value[k1]);
						if(k1=='exchange_rate2')
							exrate2.push(Value[k1]);
						if(k1=='exchange_rate1')
							exrate1.push(Value[k1]);
						
						if(k1=='taxapplyafter')
							taxapplyafter.push(Value[k1]);
						if(k1=='taxunkid')
							taxunkid.push(Value[k1]);
						if(k1=='applyonrackrate')
							applyonrackrate.push(Value[k1]);
							
						cnt++;	
				});				
			});	
	});
	
	var exchange_rate1=0;
	var exchange_rate2=0;
	var postingtype;
	var postingrule; //Manali - 1.0.36.41 - 19 Aug 2013,Purpose : Tax apply on Pax Effect
	var v_taxamount=0;
	var v_slab;
	var totaltax=0;
    var rounded_totaltax=0;
	
	var taxretarr=new Array();
	
	//console.log(taxamt.length);
	if(typeof(taxamt[0])!='undefined')
	{
		for(i=0;i<taxamt[0].length;i++)
		{			
			v_taxamount=taxamt[0][i];
			postingtype=potype[0][i];
			postingrule=porule[0][i]; //Manali - 1.0.36.41 - 19 Aug 2013,Purpose : Tax apply on Pax Effect
			exchange_rate2=exrate2[0][i];
			exchange_rate1=exrate1[0][i];
			v_slab=slab[0][i];
			//console.log(postingtype);
			v_taxapplyafter=taxapplyafter[0][i];
			v_taxunkid=taxunkid[0][i];
			v_applyonrackrate=applyonrackrate[0][i];
			
			
			var v_tamount=0;
			var v_camount=0;
			if(v_taxamount!='undefined' && exchange_rate2!='undefined' && exchange_rate1!='undefined' && postingtype!='undefined' && postingrule!='undefined') //Manali - 1.0.36.41 - 19 Aug 2013,Purpose : Tax apply on Pax Effect
			{
				if(!parseInt(flag))
				{
					if(parseInt(v_applyonrackrate))
					{
						
						if(EA==0 && EC==0)
						{
							if(callfor=='base') v_amount=BaseRate;
							if(callfor=='extraadult') v_amount=AdultRate;
							if(callfor=='extrachild') v_amount=ChldRate;
							//console.log(v_amount+"|"+EA+"|"+EC);
						}
						else
						{
							v_amount=parseFloat(BaseRate)+parseFloat(AdultRate)*parseFloat(EA)+parseFloat(ChldRate)*parseFloat(EC);
						}
						
						v_amount=parseFloat(v_amount).toFixed(decimalplaces);  
					}
					else
					{
						v_amount=realamt;	
					}	
				}
				// HRK - 1.0.25.30 - 25 Apr 2012 - START
				// Purpose : tax2 after tax1
				v_btaxapplyafter=0;
				v_ctaxapplyafter=0;
				if(v_taxapplyafter!='')
				{
					for(taxkey in taxretarr)
					{
						if((","+v_taxapplyafter+",").indexOf(","+taxkey+",")!=-1)
						{
							v_btaxapplyafter+=parseFloat(taxretarr[taxkey][0]);
							v_ctaxapplyafter+=parseFloat(taxretarr[taxkey][1]);
						}
					}
				}
				// HRK - 1.0.25.30 - 25 Apr 2012 - END
				//console.log("v_amount : " + v_amount);
				
				if(postingtype=='FLATPERCENTAGE')
				{
					v_tamount = parseFloat(v_tamount) + parseFloat((parseFloat(v_amount)+parseFloat(v_btaxapplyafter)) * parseFloat(exchange_rate2)/parseFloat(exchange_rate1)) * parseFloat(v_taxamount) / 100;
					v_camount = parseFloat(v_camount) + parseFloat((parseFloat(v_amount)+parseFloat(v_ctaxapplyafter)) * parseFloat(exchange_rate2)/parseFloat(exchange_rate1)) * parseFloat(v_taxamount) / 100;
                    
                    // Added By Romal - 18 Apr 2016 - 1.0.49.54 - For give effect of decimal place in Currency setting.
				    v_camount = roundNumber(v_camount,decimalplaces);
                    
					totaltax = parseFloat(totaltax) + parseFloat(v_camount.toFixed(decimalplaces));
                    if(multiplereturn==1) rounded_totaltax = parseFloat(rounded_totaltax) + parseFloat(v_camount.toFixed(display_decimalplace));
                    
					// HRK - 1.0.25.30 - 25 Apr 2012 - START
					// Purpose : tax2 after tax1
					taxretarr[v_taxunkid]=new Array(parseFloat((parseFloat(v_amount)+parseFloat(v_btaxapplyafter)) * parseFloat(exchange_rate2)/parseFloat(exchange_rate1)) * parseFloat(v_taxamount) / 100,parseFloat((parseFloat(v_amount)+parseFloat(v_ctaxapplyafter)) * parseFloat(exchange_rate2)/parseFloat(exchange_rate1)) * parseFloat(v_taxamount) / 100);
					// HRK - 1.0.25.30 - 25 Apr 2012 - END
				}
				else if(postingtype=='FLATAMOUNT')
				{
					//Manali - 1.0.36.41 - 19 Aug 2013 - START
					//Purpose : Tax apply on Pax Effect
					if(postingrule!='')
					{
						if(postingrule == "PERADULT")
							v_taxamount = parseFloat(v_taxamount) * parseFloat(base_adult);
						
						if(postingrule == "PERCHILD")
							v_taxamount = parseFloat(v_taxamount) * parseFloat(base_child);
							
						if(postingrule == "PERPAX")
							v_taxamount = parseFloat(v_taxamount) * (parseFloat(base_adult) + parseFloat(base_child));	
					}
					//Manali - 1.0.36.41 - 19 Aug 2013 - END
					else
					{
						if(fcurrrates !='' && WebRunForeignCurrency==1)
							v_taxamount = parseFloat(v_taxamount) * (parseFloat(fcurrrates));
					}
					v_tamount = parseFloat(v_tamount) + parseFloat(v_taxamount);
					v_camount = parseFloat(v_camount) + (parseFloat(v_tamount) * parseFloat(exchange_rate1)/parseFloat(exchange_rate2));
					totaltax = parseFloat(totaltax) + parseFloat(v_camount.toFixed(decimalplaces));
                    if(multiplereturn==1) rounded_totaltax = parseFloat(rounded_totaltax) + parseFloat(v_camount.toFixed(display_decimalplace));
					
					//tax afpply after - fixed issue - manali - 24th Sep 2015
					taxretarr[v_taxunkid]=new Array(v_taxamount,v_camount);

				}
				else if(postingtype=='SLAB')
				{
					v_taxslab = v_slab.split(",");	
					for(iSlab=0;iSlab<v_taxslab.length;iSlab++)
					{
						v_taxslab1=v_taxslab[iSlab].split("-");
						v_slabstart	  =  v_taxslab1[0];
						v_slabend 	  =  v_taxslab1[1];
						v_slabpercent =  v_taxslab1[2];
                       
						if((parseFloat(v_amount)+parseFloat(v_btaxapplyafter)) >= parseFloat(v_slabstart) && (parseFloat(v_amount)+parseFloat(v_btaxapplyafter)) <= parseFloat(v_slabend))
						{
                          //  console.log("exchange_rate2 : " + exchange_rate2);
                           // console.log("exchange_rate1 : " + exchange_rate1);
                            v_tamount = ((parseFloat(v_amount)+parseFloat(v_btaxapplyafter)) * parseFloat(exchange_rate2)/parseFloat(exchange_rate1)) * parseFloat(v_slabpercent) / 100;
                            v_camount = ((parseFloat(v_amount)+parseFloat(v_ctaxapplyafter)) * parseFloat(exchange_rate2)/parseFloat(exchange_rate1)) * parseFloat(v_slabpercent) / 100;
                           
                            // Added By Romal - 18 Apr 2016 - 1.0.49.54 - For give effect of decimal place in Currency setting.
                            v_camount = roundNumber(v_camount,decimalplaces);
                            
                            totaltax = parseFloat(totaltax) + parseFloat(v_camount.toFixed(decimalplaces));
                            if(multiplereturn==1) rounded_totaltax = parseFloat(rounded_totaltax) + parseFloat(v_camount.toFixed(display_decimalplace));
						
                            // HRK - 1.0.25.30 - 25 Apr 2012 - START
                            // Purpose : tax2 after tax1
                            taxretarr[v_taxunkid]=new Array(parseFloat((parseFloat(v_amount)+parseFloat(v_btaxapplyafter)) * parseFloat(exchange_rate2)/parseFloat(exchange_rate1)) * parseFloat(v_slabpercent) / 100,parseFloat((parseFloat(v_amount)+parseFloat(v_ctaxapplyafter)) * parseFloat(exchange_rate2)/parseFloat(exchange_rate1)) * parseFloat(v_slabpercent) / 100);
                            // HRK - 1.0.25.30 - 25 Apr 2012 - END
						}	
					}
				}	
			}//Undefined
		}
	var amt=totaltax;
	//console.log("final amt : " + amt);
	if(multiplereturn==1) return amt.toFixed(display_decimalplace).toString()+'<>'+amt.toFixed(decimalplaces).toString();
    return amt.toFixed(decimalplaces);
	}
	else
		return 0;
      //  }//if tax array not empty
   }
   catch(err){
		console.log("Error - getTax - "+err.message);
      //  return 0;
   }	
}

function _checkfbaccess()
{
	try
	{
		try
		{
			if(parent.document.domain!=serverhost && !parent.editframe)
			{	
				console.log("_resben FB code access");							
			}
			return 0;
		}
		catch(e)
		{
			try
			{
				if(!parent.editframe)				
					console.log("_resben try ........");		
					return 0;
			}
			catch(e)
			{
				console.log("_resben catch........");	
				//if(opt.siteroot==true)
				{
					return 1;
					console.log("_resben siteroot true........");
				}				
			}
		}	
	}
	catch(e)
	{
		console.log(" _resben _checkfbaccess : "+ e);	
		return 0;			
	}				
}
function fillStaticComboExtraCharges(iLimitStart,iLimitEnd,controlName,selectedValue,LCaption){
	var comboArr=[];
	comboArr.push('<option value="">'+LCaption+"</option>");
	
	for(ilmt=iLimitStart;ilmt<=iLimitEnd;ilmt++)
		comboArr.push('<option value="'+ilmt+'">'+ilmt+"</option>");
		
	jQuery("select[id$='"+controlName+"']").html(comboArr.join(""));
	
	if(selectedValue!="")
	{
		//console.log("selectedValue " + selectedValue);
		jQuery("select[id$='"+controlName+"']").val(selectedValue);
		jQuery("select[id$='"+controlName+"']").prop('selected', true);
        jQuery("select[id$='"+controlName+"'] option[value="+selectedValue+"]").attr("selected", "selected"); 
	}
}

function fillStaticCombo(iLimitStart,iLimitEnd,controlName,selectedValue)
{
        var comboArr=[];
        for(ilmt=iLimitStart;ilmt<=iLimitEnd;ilmt++)
        comboArr.push('<option value="'+ilmt+'">'+ilmt+"</option>");
        jQuery("select[id$='"+controlName+"']").html(comboArr.join(""));
        
        if(selectedValue!="")
        {
            
            jQuery("select[id$='"+controlName+"']").val(selectedValue);
            jQuery("select[id$='"+controlName+"']").prop('selected', true);
            
            jQuery("select[id$='"+controlName+"'] option[value="+selectedValue+"]").attr("selected", "selected");            
        }
}

function fillChildStaticCombo(iLimitStart,iLimitEnd,controlName,selectedValue,child_age,k_count,childcnt,result_html,col)
{
        var comboArr=[];		
		comboArr.push('<option value="">'+child_age+"</option>");
        for(ilmt=iLimitStart;ilmt<=iLimitEnd;ilmt++)
		{
			comboArr.push('<option value="'+ilmt+'">'+ilmt+"</option>");
		}
		var push_data=comboArr.join("");
		childage_html_var=result_html+'<label class="vres-select"><select id=age_'+k_count+'_'+col+'_'+child_cnt+' name=age_'+k_count+'_'+col+'_'+child_cnt+' class="childage_'+child_cnt+' agecls">'+
		push_data+
		'</select><i class="fa fa-caret-down"></i></label></div>';
		
        jQuery("select[id$='"+controlName+"']").html(comboArr.join(""));
        
        if(selectedValue!="")
        {
            jQuery("select[id$='"+controlName+"']").val(selectedValue);
            jQuery("select[id$='"+controlName+"']").prop('selected', true);
            jQuery("select[id$='"+controlName+"'] option[value="+selectedValue+"]").attr("selected", "selected"); 
            
        }		
		return childage_html_var;		
}

function fun_Integer(event){ 

	if ((event.keyCode >= 48 & event.keyCode <= 57) || (event.keyCode >= 96 & event.keyCode <= 105)) {
		return true;
	}
	if (event.keyCode >= 37 & event.keyCode <= 40) {
		if (event.keyCode == 38 )
		{
			if(parseInt(jQuery(this).val()) > 0)
			{
				jQuery(this).val(parseInt(jQuery(this).val())-1);
			}
		}
		if (event.keyCode == 40 )
		{
			if(parseInt(jQuery(this).val()) < 1000)
			{
				jQuery(this).val(parseInt(jQuery(this).val())+1);
			}
		}
		return true;
	}
	if (event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 46) {
		return true;
	}
	return false;
}

var fun_Integer_keyup = function()
{
       Nna = parseInt(jQuery(this).val(),10);
       if(isNaN(Nna))
	{
		jQuery(this).val('');
	}
       else
       {
		jQuery(this).val(Nna);
	}	
}

jQuery(document).ready(function(){
	
});

function _bookingProcess_multipleroomtype(rowid, rownumber , noofrooms) {	
	try{
		
		var _JsonObj;
		var _indexArr = new Array;
		if (typeof(resgrid[0]) != "undefined" && resgrid[0] != "") {			
			if(typeof(resgrid[0][rownumber].dt_1)!='undefined')
			{
				//_setIframeAlertPosition('roomscmb_'+rownumber);
				//alert("in");
				var chkDt=resgrid[0][rownumber].dt_1;		
				var checkout_date=new Date(jQuery.datepicker.parseDate('yy-mm-dd',chkDt));
				//alert("in" +chkDt );
				//alert("checkout_date" +checkout_date );
				if(jQuery("select[id$='eZ_Nights']").val()!='' && jQuery("select[id$='eZ_Nights']").val()!=null && typeof(jQuery("select[id$='eZ_Nights']").val())!='undefined')
					var no_nights=jQuery("select[id$='eZ_Nights']").val();
				else if(jQuery("input[id$='eZ_Nights2']").val()!='')
					var no_nights=jQuery("input[id$='eZ_Nights2']").val();
				else if(jQuery("input[id$='eZ_Nights']").val()!='')
					var no_nights=jQuery("input[id$='eZ_Nights']").val();	
				//alert("no_nights" +no_nights );
				
				checkout_date.setDate(checkout_date.getDate() + parseInt(no_nights));
				checkout_date = jQuery.datepicker.formatDate("yy-mm-dd", checkout_date);
				_JsonObj = eval(resgrid[0][rownumber]);
				//console.log(_JsonObj['display_name']);
				for(k=1;k<=parseInt(no_nights);k++)
				{
					_indexArr.push(k);	
				}
				//console.log(_JsonObj);
				//console.log(_indexArr + " : " + rownumber + " : " + parseInt(no_nights) + " ; " + noofrooms );
				//return false;
				//hideLoadingBar();
				//return true;
				return _checkavailabilityfunc(_JsonObj,_indexArr,rownumber,parseInt(no_nights),1,noofrooms);
			}
			else 
			{
				//_setIframeAlertPosition('roomscmb_'+rownumber);				
                swal({
                                title: '',//title_message,
                                text: js_stripslashes(langjs[0]["L_WrongDataSelection"]),
                                type: 'error',
                                showCancelButton: false,
                                animation:false																					
                             });
				hideLoadingBar();
				return false;
			}
		}
		else {				
                 swal({
                                title: '',//title_message,
                                text: js_stripslashes(langjs[0]["L_WrongDataEntered"]),
                                type: 'error',
                                showCancelButton: false,
                                animation:false																					
                             });
				hideLoadingBar();
				return false;
		}
	}	
	catch(err){
         console.log("Error - _bookingProcess_multipleroomtype - "+err.message);
    }
	
}//_bookingProcess


function _checkavailabilityfunc(_JsonObj,_indexArr,rownumber,droppedCnt,multiflag,noofrooms)
{
	try
	{
		console.log("call _checkavailabilityfunc");
       
		//console.log(checked_liveroomavail);
		var _Store = _indexArr[_indexArr.length - 1];
		var codIndex = parseInt(_Store) + 1;
		var t_date;
		
		//if (rownumber!='') {
		//	_setIframeAlertPosition('roomscmb_'+rownumber);
		//}
		for (var _cmp = 0; _cmp < _indexArr.length; _cmp++) {
            //  console.log(_JsonObj["day_base_" + _indexArr[_cmp]]);
			if(_JsonObj["day_base_" + _indexArr[_cmp]] < 0)
			{
                swal({
                    title: '',//title_message,
                    text: js_stripslashes(langjs[0]["L_NegativeMsg"]),
                    type: 'error',
                    showCancelButton: false,
                    animation:false																					
                 });  
				
				hideLoadingBar();
				return false;
			}
			
			if(jQuery("#rooms_"+rownumber).is(':visible'))
				var compareroom=jQuery("#rooms_"+rownumber).val();
			else
				var compareroom=1;
			
			if((parseInt(_JsonObj["day_" + _indexArr[_cmp]]) == 0 || parseInt(_JsonObj["stopsell_" + _indexArr[_cmp]]) == 1 || typeof _JsonObj["day_" + _indexArr[_cmp]] == "undefined" || parseInt(_JsonObj["day_" + _indexArr[_cmp]])<parseInt(compareroom)))
			{							
				t_date=new Date(jQuery.datepicker.parseDate('yy-mm-dd',_JsonObj["dt_"+_indexArr[_cmp]]));
				t_date = jQuery.datepicker.formatDate("dd-M-yy", t_date);
				var message_not=js_stripslashes(langjs[0]["L_RoomsNotAvailable"]).replace("{number}",_JsonObj["day_" + _indexArr[_cmp]]).replace("{day}", t_date);
			    swal({
                    title: '',//title_message,
                    text: message_not,
                    type: 'warning',
                    showCancelButton: false,
                    animation:false																					
                 }); 
				hideLoadingBar();
				return false;
			}
			
			var adult_chk=0;                       
			jQuery("select[id^=rm_adult_"+rownumber+"_]").each(function(){                           
			   compareadult=parseInt(jQuery(this).val());		 
			   if((parseInt(_JsonObj["max_adult"]) < parseInt(compareadult))){
					adult_chk++; 
				 }
			});
			if (adult_chk>0) {             
                 swal({
                    title: '',//title_message,
                    text: js_stripslashes(langjs[0]["L_AdultsNotAvaialbe"]).replace("{number}",_JsonObj["max_adult"]),
                    type: 'warning',
                    showCancelButton: false,
                    animation:false																					
                 }); 
				hideLoadingBar();
				return false;
            }
			
			var child_chk=0;
			var maxchild_chk=0;
			jQuery("select[id^=rm_child_"+rownumber+"_]").each(function(){                           
			   comparechild=parseInt(jQuery(this).val());
			   if((parseInt(_JsonObj["max_child"]) < parseInt(comparechild))){
					if(parseInt(_JsonObj["max_child"])==0){
						maxchild_chk++;
					}
					else
					{
						child_chk++;
					}
			   }
			});
			if (child_chk>0) {
                swal({
                    title: '',//title_message,
                    text: js_stripslashes(langjs[0]["L_ChildsNotAvailabe"]).replace("{number}",_JsonObj["max_child"]), 
                    type: 'warning',
                    showCancelButton: false,
                    animation:false																					
                 }); 
				hideLoadingBar();
				return false;
            }
			if (maxchild_chk>0) {
                 swal({
                    title: '',//title_message,
                    text: js_stripslashes(langjs[0]["L_NoChildAllowed"]),
                    type: 'warning',
                    showCancelButton: false,
                    animation:false																					
                 }); 
				hideLoadingBar();
				return false;
            }						
			
			if (parseInt(_JsonObj["coa_" + _indexArr[0]]) == 1) {
			    swal({
                    title: '',//title_message,
                    text: js_stripslashes(langjs[0]["L_CheckInNotAllowed"]),
                    type: 'warning',
                    showCancelButton: false,
                    animation:false																					
                 }); 
				hideLoadingBar();
				return false;
			}
			if (parseInt(_JsonObj["cod_" + codIndex]) == 1) {
			     swal({
                    title: '',//title_message,
                    text: js_stripslashes(langjs[0]["L_CheckOutNotAllowed"]),
                    type: 'warning',
                    showCancelButton: false,
                    animation:false																					
                 }); 
				hideLoadingBar();
				return false;
			}
			
			//Fixed bug to solved minimum nights issue , 4,1,1,1 , not allow to book if for 4,1 OR 1,4 in check in & out date.
			//Booking.com checking for maximum nights in minimum stay
			//Expedia.com allowing check on only arrival date
			//Applied fix for book.com solution
			/***********************************/
			find_min_nights=[];			
			for(mn_ni=1;mn_ni<=_indexArr.length;mn_ni++)
			{
			   mn_val=parseInt(_JsonObj["min_night_"+_indexArr[mn_ni-1]]);
			   find_min_nights.push(mn_val);
			}			
			var min_night_val=Math.max.apply(Math,find_min_nights);			
			/***********************************/
			
			if (parseInt(droppedCnt) < parseInt(min_night_val)) {
                 swal({
                    title: '',//title_message,
                    text: js_stripslashes(langjs[0]["L_PleaseSelect"]) + min_night_val + js_stripslashes(langjs[0]["L_NightsToBook"]),
                    type: 'warning',
                    showCancelButton: false,
                    animation:false																					
                 }); 
				hideLoadingBar();
				return false;
			}		
		}
		return true;
	}
	catch(err)
	{
		console.log("Error - _checkavailabilityfunc "+err.message);	
	}
}


function unique(list) {
    var result = [];
    $.each(list, function(i, e) {
        if ($.inArray(e, result) == -1) result.push(e);
    });
    return result;
}

function getAlertContent(closealert,keynamepop)
{
	try
	{
        if (closealert=='1') {
            var ick=0;
            try {
                $('html').mouseleave(function(e) {
                if(e.clientY < 0){
                        
                    if (ick==0 && $("#loadingbar").is(':hidden')==true && $(".vres-prog-wrap").is(':hidden')==true) {
                        if (ick==0) {
                                //if(('sessionStorage' in window))
                                //{
                                //    sessionStorage.setItem(keynamepop,true);
                                //}
                                
                                document.cookie = keynamepop+"=true";
                                
                                $("[data-dismiss=modal]").trigger({ type: "click" });
                                ick++;
                                var modalObj = $('#myModalPopUpcontent').modal(); // initialize
                                modalObj.modal('show') // show
                                
                                var isInIframe = (window.location != window.parent.location) ? true : false;
                                if (isInIframe==true) {
                                    $('#myModalPopUpcontent').css('top','20%');
                                }
                        }
                    }
				}
			});
            } catch(e) {
               
            }			
		}
	}
    catch(e)
    {
		console.log("Error - getAlertContent- "+e.message);
    }
}

jQuery(function(){
	var disableHtml=function(element){element.value=element.value.replace(/[<>]/g,"")};
	var NonSpecialCharacter=function(element){element.value=element.value.replace(/[<>,!={}|#$%^&*()[\]@_+]/g,"")}; //Prashant Patel - 23 March 2021 - Input Field Validations[RES-2714]
	var addcitystatevarify = function(element){element.value=element.value.replace(/[\\+()$!~%^;.'":*?<>{}]/g,"")}; //Chirag RES-2567 
	
	jQuery(".txtarea").on('focusout',function(){disableHtml(this)});
	jQuery(".txtbox").on('focusout',function(){disableHtml(this)});
	jQuery(".nonspecialchar").on('focusout',function(){NonSpecialCharacter(this)});
	jQuery(".addcitystate").on('focusout',function(){addcitystatevarify(this)}); //Chirag RES-2567
	
	jQuery(".txtDecimal").keydown(fun_decimal);jQuery(".txtDecimal").focusout(fun_Decimal_keyup);	
	jQuery(".txtInteger").on('keydown',fun_Integer); 
	jQuery(".txtInteger").on('focusout',fun_Integer_keyup);
       
       jQuery(".txtInteger2").on('keydown',fun_Integer); //Chandrakant - 1.0.52.57 - 10 May 2017
});


//function fun_Integer(event){
//       // alert(event.keyCode);
//	if(event.keyCode>=48&event.keyCode<=57||event.keyCode>=96&event.keyCode<=105)return true;
//	if(event.keyCode>=37&event.keyCode<=40){
//		if(event.keyCode==38)if(parseInt(jQuery(this).val())>0)jQuery(this).val(parseInt(jQuery(this).val())-1);
//		if(event.keyCode==40)if(parseInt(jQuery(this).val())<1E3)jQuery(this).val(parseInt(jQuery(this).val())+1);return true}
//		if(event.keyCode==8||event.keyCode==9||event.keyCode==46)return true;return false}
//		
//var fun_Integer_keyup=function(){Nna=parseInt(jQuery(this).val(),10);if(isNaN(Nna)){jQuery(this).val("");}else{jQuery(this).val(Nna)}};
//
function fun_decimal(event){var regex=/^[\w]+(\.[\w]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;if(event.keyCode==110||event.keyCode==190)if(jQuery(this).val().indexOf(".")==-1){if(jQuery(this).val().trim()=="")jQuery(this).val("0");return true}if(event.keyCode>=48&event.keyCode<=57||event.keyCode>=96&event.keyCode<=105)return true;if(event.keyCode>=37&event.keyCode<=40)return true;if(event.keyCode==8||event.keyCode==9||event.keyCode==46)return true;return false}

var fun_Decimal_keyup=function(){Nna=parseFloat(jQuery(this).val());if(isNaN(Nna))jQuery(this).val("");else jQuery(this).val(Nna)};



function checkHowManyViewers(ViewersCnt)
{
 	//Toal Viewers Count
	if(ViewersCnt > 1 )
	{
		var viewerscnt_msg2=js_stripslashes(langjs[0]["L_ViewersCnt1"]) +" " + ViewersCnt +" " +js_stripslashes(langjs[0]["L_ViewersCnt2"]) +" "+ js_stripslashes(langjs[0]["L_ViewersCnt3"]);
		jQuery("#lookinguser").html(viewerscnt_msg2);
	}
}

window.stack_bottomleft = {"dir1": "up", "dir2": "right", "spacing1": 5, "spacing2": 5};
function FindRecentbooking(TotRecentBooking)
{
	//Recent Booking    
	if(TotRecentBooking > 0 )
	{
        jQuery("#dis_findrecentbooking").show();
		var recentbooking_msg=js_stripslashes(langjs[0]["L_RecentBook1"]) +" " + $.trim(TotRecentBooking) +" " +js_stripslashes(langjs[0]["L_RecentBook2"]);
        
		//jQuery("#recentbooking").html(recentbooking_msg);
        
        setTimeout(function(){
           // $('#dis_findrecentbooking').addClass('notify-open');
           var notice = new PNotify({
                        text: recentbooking_msg,
                        delay : 5000,
                        addclass : 'stack-bottomleft',
                        stack : stack_bottomleft,
                        icon : 'fa fa-calendar-check-o',
                        buttons: {
                            closer: true,
                            sticker: false,
                            closer_hover : false
                        },
                        animate: {
                            animate: true,
                            in_class: 'bounceInLeft',
                            out_class: 'bounceOutLeft'
                        },
                        mobile: {
                                swipe_dismiss: true,
                                styling: false
                        },
                    });
           
        }, 3000);
	}
}

function TotalGuestCountryWise(guest_country,noof_guest)
{
	//Guest booking country wise
   // console.log("noof_guest : " + noof_guest);
	if(noof_guest > 0)
	{
        //jQuery("#dis_totalguestcountry").show();
		var guest_msg=noof_guest + " " + js_stripslashes(langjs[0]["L_GuestCountryNote1"]) + " "+ $.trim(guest_country)+ " " +js_stripslashes(langjs[0]["L_GuestCountryNote2"]);
		//jQuery("#totalguestcountry").html(guest_msg);
        
        setTimeout(function(){
            //$('#dis_totalguestcountry').addClass('notify-open');
            var noticecountry = new PNotify({
                                        text: guest_msg,
                                        delay : 7000,
                                        addclass : 'stack-bottomleft',
                                        stack : stack_bottomleft,
                                        icon : 'fa fa-globe',
                                        
                                        buttons: {
                                            closer: true,
                                            sticker: false,
                                            closer_hover : false
                                        },
                                        animate: {
                                            animate: true,
                                            in_class: 'bounceInLeft',
                                            out_class: 'bounceOutLeft'
                                        },
                                         mobile: {
                                                swipe_dismiss: true,
                                                styling: false
                                        },
                                    });
        }, 13000);
	}	
}

function TotalReviewAlerts(tot_reviews,tot_scores,showreview)
{
	if(tot_reviews > 0 && showreview==1 )
	{
       // jQuery("#dis_review").show();
        var review_scores=parseFloat(tot_scores).toFixed(1);
		
		var review_msg=js_stripslashes(langjs[0]["L_reviewsnote"]) +" " + review_scores+" / 5";
        //alert(review_msg);
		//jQuery("#reviewtotal_score").html(review_msg);
        
        setTimeout(function(){
          //  $('#dis_review').addClass('notify-open');
          var noticereview = new PNotify({
                                text: review_msg,
                                delay : 7000,
                                addclass : 'stack-bottomleft',
                                stack : stack_bottomleft,
                                icon : 'fa fa-star-half-empty',
                                buttons: {
                                    closer: true,
                                    sticker: false,
                                    closer_hover : false
                                },
                                animate: {
                                    animate: true,
                                    in_class: 'bounceInLeft',
                                    out_class: 'bounceOutLeft'
                                },
                                 mobile: {
                                        swipe_dismiss: true,
                                        styling: false
                                },
                            });
        }, 22000);
	}	
}

//set iframe extra charges scroll to top
function _setiframetop()
{
        try{
                if(typeof(top.frames['editframe'])!='undefined' ) {                
                        var hidata = jQuery("#paymentblock,.row_bookingstatus").position().top;
                        console.log("_setiframetop hidata "+ hidata);
                        parent.postMessage({'action':'EXTRA', 'top':hidata},"*");
                }
        }
        catch(e)
        {
            console.log("_setiframetop" + e);	    
        }
}

function _setExternalIframeHeight()
{
       try {		
                var hidata=jQuery("#re_main_div").height();
                parent.postMessage(hidata,"*");
                console.log("height  " + hidata);
            
                var _cssName=serverurl+"templates/resui/css/";		
                if (typeof(top.frames['editframe'])!='undefined' ) {
                    console.log("_setExternalIframeHeight call");
                    var frm = top.frames['editframe'].document;
                    var frame_name=top.frames['editframe'];
                    var otherhead = frm.getElementsByTagName("body")[0];
                    
                    var cssSelector = document.getElementById("uiCss");
                    if (!cssSelector) {
                        var link = frm.createElement("link");
                        link.setAttribute("rel", "stylesheet");
                        link.setAttribute("type", "text/css");
                        link.setAttribute("href", _cssName+"resframecss.css");
                        link.setAttribute("ID", "uiCss");
                        otherhead.appendChild(link);
                    }
                    
                }
        } catch(e) {
        console.log("_setExternalIframeHeight" + e);		
        }	 
}

function _setIframePopupPosition(id,model_id){
        try{		    
         console.log("_setIframePopupPosition- " +id);
          if (typeof(top.frames['editframe'])!='undefined' ) {
            
            var obj_html=id;
            
            var content_height = parseInt(jQuery("#"+obj_html).offset().top);//content height                    
            var popup_height=parseInt(model_id.height());//popup height                    
            var page_height=parseInt(jQuery("#re_main_div").height());
             
            var total_top=page_height-content_height;
            
            
            if(total_top < popup_height) {
               var set_height=content_height - popup_height;
            }
            else
            {
                var set_height=content_height + 50;
            }
            jQuery(".modal.fade.in").css('top',set_height);
            model_id.css('top',set_height);            
            model_id.scrollTop();                                                      
        }		    							   
    }
    catch(e)
    {
        console.log("Error - _setIframePopupPosition- "+e.message);
    }
}
//Getting banner image from local storage
function getbannerimage(hotelid,id)
{
       try
       {
              var keyname="res_Logo_"+hotelid;
              var keyname_tab="res_Logo_Tab_"+hotelid;
              var keyname_mobile="res_Logo_Mobile_"+hotelid;
              
              var name = keyname + "=";
              var name_tab = keyname_tab + "=";
              var name_mobile = keyname_mobile + "=";
                                   
              var ca = document.cookie.split(';');
              var cookie_img=cookie_img_tab=cookie_m='';
              
              for(var i = 0; i <ca.length; i++) {
                     var c = ca[i];
                     while (c.charAt(0)==' ') {
                        c = c.substring(1);
                     }
                     if (c.indexOf(name) == 0)
                     {
                        cookie_img=c.substring(name.length,c.length)
                     }
                     else if (c.indexOf(name_tab) == 0)
                     {
                        cookie_img_tab=c.substring(name_tab.length,c.length)
                     }
                     else if (c.indexOf(name_mobile) == 0)
                     {
                        cookie_m=c.substring(name_mobile.length,c.length)
                     }
              }
              
              if (cookie_img!='')
              {
                     var imageurl=cookie_img;
                     
                     if(imageurl == '' || imageurl=='no-bnr')
                     {
                            $("#"+id).parent().next().addClass("no-bnr");
                            $("#"+id).remove();
                            $("#addreview_banner_tab").remove();
                            $("#addreview_banner_mobile").remove();
                     }
                     else
                     {
                            document.cookie = keyname+"="+imageurl;
                            $("#"+id).attr("src",imageurl);
                            
                            if(cookie_img_tab!=undefined && cookie_img_tab!='')
                            {
                                   document.cookie = keyname_tab+"="+cookie_img_tab;
                                   jQuery("#addreview_banner_tab").attr("src",cookie_img_tab);
                            }
                            else
                            {
                                   jQuery("#addreview_banner_tab").attr("src",imageurl);
                            }
                            
                            if(cookie_m!=undefined && cookie_m!='') 
                            {
                                   document.cookie = keyname_mobile+"="+cookie_m;
                                   jQuery("#addreview_banner_mobile").attr("src",cookie_m);
                            }
                            else
                            {
                                   jQuery("#addreview_banner_mobile").attr("src",imageurl);
                            }
                     }
                     
                     $('#'+id).error(function() {
                            $("#"+id).parent().next().addClass("no-bnr");
                            $("#"+id).remove();
                            $("#addreview_banner_tab").remove();
                            $("#addreview_banner_mobile").remove();
                     });
              }
              else
              {
                     getUrl=serverurl+'service.php';
                     $.ajax({
                            url: getUrl,
                            type: "POST",
                            data: {
                                   action: 'LogoBannerImage',
                                   HotelId: hotelid
                            },
                            dataType: "html",
                            success: function( data ){
                                   var imageurl=JSON.parse(jQuery.trim(data));
                                   if(imageurl.DESKTOP=='')
                                   {
                                          $("#"+id).parent().next().addClass("no-bnr");
                                          $("#"+id).remove();
                                          $("#addreview_banner_tab").remove();
                                          $("#addreview_banner_mobile").remove();
                                          document.cookie = keyname+"="+"no-bnr";
                                          document.cookie = keyname_tab+"="+"no-bnr";
                                          document.cookie = keyname_mobile+"="+"no-bnr";
                                   }
                                   else
                                   {
                                          $("#"+id).attr("src",imageurl.DESKTOP);
                                          document.cookie = keyname+"="+imageurl.DESKTOP;
                                         
                                          if(imageurl.TAB!=undefined && imageurl.TAB!='')
                                          {
                                                 $("#addreview_banner_tab").attr("src",imageurl.TAB);
                                                 document.cookie = keyname_tab+"="+imageurl.TAB;
                                          }
                                          else
                                          {
                                                 $("#addreview_banner_tab").attr("src",imageurl.DESKTOP);
                                                 document.cookie = keyname_tab+"="+imageurl.DESKTOP;
                                          }
                                                 
                                          if(imageurl.MOBILE!=undefined && imageurl.MOBILE!='')
                                          {
                                                 $("#addreview_banner_mobile").attr("src",imageurl.MOBILE);
                                                 document.cookie = keyname_mobile+"="+imageurl.MOBILE;
                                          }
                                          else
                                          {
                                                 $("#addreview_banner_mobile").attr("src",imageurl.DESKTOP);
                                                 document.cookie = keyname_mobile+"="+imageurl.DESKTOP;
                                          }
                                   }
                            },
                            complete:function(){
                                   $('#'+id).error(function() {
                                          $("#"+id).parent().next().addClass("no-bnr");
                                          $("#"+id).remove();
                                   });
                            }
                     });						
              }
       }
       catch(e)
       {
           console.log("getbannerimage - "+e);
       }
}


//Romal - 1.0.48.53 - 25 March  2016 - START
function roundNumber(value, exp) {
  if (typeof exp === 'undefined' || +exp === 0)
    return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
    return NaN;

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}
//Romal - 1.0.48.53 - 25 March  2016 - END

// JavaScript Document - localstorage.js
/******************  Default functions - start **************************/
var resLocalStorage = {};

resLocalStorage.getItem = function(key) {
    return new MyItem(JSON.parse(localStorage.getItem(key)));
};

resLocalStorage.getItemObject = function(key) {
    return JSON.parse(localStorage.getItem(key));
};

resLocalStorage.setItem = function(key, object) {	
    localStorage.setItem(key, JSON.stringify(object));
    return this.getItem(key);
};

resLocalStorage.removeItem = function(key) {
   localStorage.removeItem(key);
};

resLocalStorage.setUniqueItem = function(key, value) {
    localStorage.setItem(key, value);
	//alert(localStorage.getItem(key));
};
resLocalStorage.getUniqueItem = function(key) {
    return localStorage.getItem(key);
};

function MyItem(object, key) {
    this.item = object;
    this.key = key;
    this.addSubItem = function(subItem) {
        if (typeof this.item.push == 'function') {
            this.item.push(subItem);
           // this.save();
            return this;
        }
        return false;
    };
    this.setItem = function(object) {
        this.item = object;
        //this.save();
        return this;
    };
    this.save = function() {
        resLocalStorage.setItem(this.key, this.item);
    };
    this.toString = function() {
        return this.item.toString();
    }
}
//Remove Item
function removeAllItem()
{	
	//localStorage.clear();
}

//Minesh - 16 Feb 2021 - START, Purpose - discard special character in zip code for ABS-5764
function validZipCode(v)
{
	try
	{
        v.value = v.value.replace(/\"/g,'').replace(/\'/g,'').replace(/[<>]/g,'').replace(/[(),+!@#\$\%\^&*_=.\{\}\\|\[\];:\?\/`~]/g,"");
  }
	catch(e)
	{
		return false;
	}
}
//Minesh - 16 Feb 2021 - END
/******************  Default functions - end  **************************/


